import React from "react"
import { pipesToSpan } from "../util/common"

const Box1 = ({ title, items }) => {

  const titleHtml = pipesToSpan(title)


  return (
    <section className="py-16 lg:pb-32 lg:pt-16 bg-[#FAFAFD]">
      <div className="container -max-w-[1180px] mx-auto space-y-12 lg:space-y-24">
        <h2 className="H2 max-w-[700px] mx-auto" dangerouslySetInnerHTML={{ __html: titleHtml }} />
        <div className="grid lg:grid-cols-3 xl:grid-cols-4 grid-cols-1  gap-x-8	gap-y-14 mx-12">
          {items.map((item, index) => (
            <div key={index}
              className="border border-blue rounded-md">
              <div className=" p-5 pr-8 h-full  flex flex-col border lg:border-none border-blue shadow rounded">

                <div className="flex flex-row items-center mb-2 ">
                  <div className=" mr-2  ">
                    <img src={item?.relationships?.image?.localFile?.publicURL} alt="" className="" />
                  </div>
                  <h3 className="text-blue  --mb-4  font-black	 text-sm uppercase  " >{item.title}</h3>
                </div>
                <p className=" text-grey  text-base max-w-[330px] ">{item.description}</p>
              </div>
            </div>

          ))}
        </div>
      </div>
    </section>
  )
}

export default Box1
