import React from "react"
import { graphql } from "gatsby"
import { Layout3 } from "../components/layout"
import Image from "../components/Image"
import getMetatags from "../util/getMetatags"
import Seo from "../components/Seo"
import HubspotForm from 'react-hubspot-form'
import { TrustedBySimple } from "../components/trusted-by"
import TestimonialsSlideshowHp from "../components/testimonials-slideshow2"
import useViewportWidth from "../hooks/useViewportWidth"
import { LogoHeader } from "../components/header"
import { relativeToAbsulote } from "../util/common"
import Box1 from "../components/features-pack-8-simple-block"

function BookADemoLanding({ data: { nodeBookADemo }, pageContext: { isThankYou }, ...props }) {

  const width = useViewportWidth()
  const isMobile = width <= 768

  //console.log('isMobile', isMobile, width)

  const {
    path,
    trustedByTitle,
    relationships,
    calendarUrl,
    title1,
    title2,
    testimonialShow,
    testimonialTitle
  } = nodeBookADemo

  const { left, hubspot, thankyou, trustedBy, box1, box2 } = relationships
  const seo = getMetatags(
    nodeBookADemo.metatag,
    nodeBookADemo.relationships.OGImage?.localFile?.publicURL
  )

  seo.meta.push({
    name: "robots",
    content: "noindex, nofollow"
  })

  left.trustedBy = trustedBy
  left.trustedByTitle = trustedByTitle


  if (!isThankYou) {
    return (
      <Layout3>
        <Seo {...seo} />
        <div className="flex flex-col lg:flex-row w-full -h-full -min-h-screen">
          <div className="lg:w-1/2 -h-full	-min-h-screen lg:px-8">
            <LogoHeader />
            <Main left={left} />
          </div>
          <div className={"lg:w-1/2 bg-blue text-white 	-min-h-screen lg:px-12 lg:py-6 lg:bg-[url('/background/BookADemo.png')] bg-no-repeat bg-top lg:pt-8 "}>
            <Side {...hubspot} path={path.alias} />
          </div>
        </div>
        <div>
          {title1 && <Box1 title={title1} items={box1} />}
          {title2 && <Box2 title={title2} items={box2} />}
          {width > 0 && testimonialShow && testimonialTitle &&
            <TestimonialsSlideshowHp
              title={testimonialTitle}
              isMobile={isMobile}
              isHomepage={false}
            />
          }
        </div>
      </Layout3>
    )
  } else {
    return (
      <Layout3>
        <Seo {...seo} />
        <div className="flex flex-col lg:flex-row w-full -h-full -min-h-screen">
          <div className="lg:w-1/2 -h-full	-min-h-screen lg:px-8">
            <LogoHeader />
            <MainThankYou thankyou={thankyou} calendarUrl={calendarUrl} />
          </div>
          <div className={"lg:w-1/2 bg-blue text-white 	-min-h-screen lg:px-12 lg:py-6 lg:bg-[url('/background/BookADemo.png')] bg-no-repeat bg-top lg:pt-8 "}>
            <SideThankYou thankyou={thankyou} />
          </div>
        </div>
      </Layout3>
    )
  }

}

const Main = ({ left }) => {
  return (
    <div className="lg:pt-0 lg:px-12 pt-2 pb-8 lg:pb-0 px-8">
      <h1 className="text-blue text-2xl 2xl:text-subtitle-l-bold font-bold  lg:py-4 py-2" dangerouslySetInnerHTML={{ __html: left.title }}></h1>
      <div className="text-base lg:text-xl	lg:leading-8 pb-2" dangerouslySetInnerHTML={{ __html: relativeToAbsulote(left?.description_html?.processed) }}></div>
      <ul className="text-base lg:text-xl	lg:leading-8 list-none list-inside pb-0">
        {left.bullets.map((s, index) => (
          <li className="pb-3" key={index}><span className="text-lg lg:text-2xl">✓</span> {s}</li>
        ))}
      </ul>
      <p className="text-base lg:text-xl	lg:leading-8 pb-1">{left.description}</p>

      <TrustedBySimple
        items={left.trustedBy}
        title={left.trustedByTitle}
        className="pt-4 pb-4"
        h4ClassName="mb-2"
      />
    </div>
  )
}

const MainThankYou = ({ thankyou, calendarUrl }) => {

  return (
    <div className="lg:pt-12 lg:px-12 pt-8 px-8 pb-24 ">
      <h1 className="text-5xl py-8 leading-[3.5rem]">{thankyou.title}</h1>
      <p className="text-3xl pb-12 font-bold">{thankyou.description}</p>
      <div className="text-2xl pb-4" dangerouslySetInnerHTML={{ __html: relativeToAbsulote(thankyou?.description_html?.processed) }} />

      <iframe title="Calendar" src={calendarUrl} width={'100%'} height={'810px'} className="" />
    </div>
  )
}


const Side = ({ portal_id, form_id, title, sub_title, path/* , mode */ }) => {


  const ready = (form) => {

    //console.log('Form ready!')
    //console.log(form)

    var container1 = document.getElementsByClassName("legal-consent-container")[0];
    var fieldset1 = container1.closest('fieldset')
    var container2 = document.getElementsByClassName("hs_receive_news")[0];
    var fieldset2 = container2.closest('fieldset')

    /*
    console.log(container1)
    console.log(fieldset1)
    console.log(container2)
    console.log(fieldset2)
    */

    if (fieldset1 && fieldset2) {
      swapElements(fieldset1, fieldset2)
    }
  }

  const swapElements = (elm1, elm2) => {
    var parent1, next1,
      parent2, next2;

    parent1 = elm1.parentNode;
    next1 = elm1.nextSibling;
    parent2 = elm2.parentNode;
    next2 = elm2.nextSibling;

    parent1.insertBefore(elm2, next1);
    parent2.insertBefore(elm1, next2);
  }

  //const classes1 = mode === 'regular' ? 'lg:p-8 xl:p-12 2xl:p-20' : ' '
  //const classes2 = mode === 'regular' ? '' : ' '

  return (
    <div className={"container p-3 "}>

      <div className={"bg-white text-grey  rounded-xl py-6 px-10 "}>
        <h2 className="text-blue  text-3xl lg:subtitle-m-bold font-bold py-2">{title}</h2>
        <p className="py-4">{sub_title}</p>
        <HubspotForm
          portalId={portal_id}
          formId={form_id}

          //cssRequired=""
          css=" "  // !important
          cssClass="sigthfull-hubspot"

          submitButtonClass="sigthfull-hubspot-submit-btn c-btn"
          errorClass="sigthfull-hubspot-error"
          errorMessageClass="sigthfull-hubspot-error-message"


          //onSubmit={(e) => console.log('Submit!')}
          //onFromSubmit={(e) => console.log('on form submit')}
          //onFormSubmitted={(e) => console.log('on onFormSubmitted')}
          onReady={ready}
          //loading={<div>Loading...</div>}
          redirectUrl={path + '/thank-you'}

        />
      </div>
    </div>
  )
}





const SideThankYou = ({ thankyou }) => {

  return (
    <div className="container lg:p-20 p-6 bg-blue h-[100%]">
      <div className=" text-grey py-12 px-10 rounded-xl">
        <Image image={thankyou.relationships.image} alt={thankyou.image.alt} />
      </div>
    </div >
  )
}


const Box2 = ({ title, items }) => {

  return (
    <section className="py-12 lg:py-32 bg-dark-blue text-white">
      <div className="container mx-auto max-w-[1180px] space-y-24">
        <h2 className="H2 max-w-[866px] mx-auto ">{title}</h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-16 gap-y-16 lg:gap-y-24">
          {items.map((item, index) => (
            <div key={index} className="">
              <div className="mb-6">
                <img src={item.relationships.image.localFile.publicURL} alt={item.image.alt} />
              </div>
              <h3 className="text-[22px] mb-3 font-bold">{item.title}</h3>
              <p className="text-base">{item.description}</p>

            </div>
          ))}
        </div>
      </div>
    </section>
  )
}
export default BookADemoLanding

export const query = graphql`
  query bookADemoLanding($id: String!) {
    nodeBookADemo(id: { eq: $id }) {
      id
      title
      trustedByTitle: field_title2
      path {
        alias
      }
      showCTA: field_show_book_demo

      testimonialShow : field_show_testimonials
      testimonialTitle : field_title_5

      metatag {
        attributes {
          content
          name
        }
        tag
      }
      calendarUrl:field_title
      title1: field_title3
      title2: field_title4
      relationships {
        left: field_hero {
          ...ParagraphGenericBlock
        }
        thankyou: field_paragraph_single{
          ...ParagraphGenericBlock
        }
        hubspot: field_hubspot {
          ...ParagraphHubspotContent
        }
        trustedBy: field_paragraph_repeter {
          image: field_image {
            title
            alt
          }
          relationships {
            image: field_image {
              uri {
                url
              }
              localFile {
                url
                publicURL
              }
            }
          }
        }
        box1: field_paragraph_repeater2 {
          ...ParagraphImageAndText
        }
        box2: field_items {
          ...ParagraphImageAndText
        }
        OGImage: field_image {
          localFile {
            url
            publicURL
          }
        }
      }
    }
  }
`

/*
export const HubspotGraphsqQuery = graphql`
  fragment HubspotContent on paragraph__hubspot {
    portal_id: field_hubspot_portal_id
    form_id: field_hubspot_form_id
    sub_title: field_sub_title
    title: field_title
  }
`
 */
